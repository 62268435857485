import React, { useState } from 'react';
import Slider from 'react-slick';
import { Col, Row,  Card } from 'react-bootstrap';
import SliderArrow from '../images/sliderarrow.svg';

const Requirements = () => {
    const [activeTab, setActiveTab] = useState('procedimiento');
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomNextArrow />,
    };

    function CustomNextArrow(props) {
        
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-slick-arrow`} // Added custom class for extra styling
                style={{ 
                    ...style,
                    display: "block",
                    background: `url(${SliderArrow}) no-repeat center center`,
                    backgroundSize: 'contain',
                    width: '36px',
                    height: '36px',
                    right: '5%', // Adjust to move in from the right edge
                    position: 'absolute', // Ensure it's positioned absolutely within the slider
                    zIndex: 1
                }}
                onClick={onClick}
            />
        );
    }
    
    // Handler to change active tab
    const handleSelectTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
        <div style={{backgroundColor: '#ECF8FF'}} className='d-none d-md-block'>
            <div className="text-center" id='documentos'>
                <p className='text-primary beba-font pt-5' style={{marginBottom: '-3px', fontSize: '5em'}}>Procedimientos previos</p>
                
                <ul className="nav nav-tabs justify-content-center mt-2" role="tablist">
                    <li className="nav-item" role="presentation" style={{borderRight: '1.5px solid #000000'}}>
                        <div className={`nav-link ${activeTab === 'procedimiento ' ? 'active' : ''}`} onClick={() => handleSelectTab('procedimiento')}>Seguro Full Cover</div>
                    </li>
                        <li className="nav-item" role="presentation">
                        <div className={`nav-link ${activeTab === 'adicionales' ? 'active' : ''}`} onClick={() => handleSelectTab('adicionales')}>Soporte 24/7</div>
                    </li>
                </ul>
                {/* Displaying variable data based on active tab */}
                <div>
                    {activeTab === 'adicionales' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-30'>Nos importan nuestros clientes y les ofrecemos la mejor experiencia posible. Estamos disponibles 24/7 para resolver cualquier problema que surja.</p></div> }
                </div>
            </div>
                <Slider {...settings} className='mt-5 px-5'>
                <div>
                    <Row className='mb-5'>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>
                                    1
                                </h1>
                                <h2 className='beba-font'>Requisitos completos</h2>
                            </Card.Title>
                            <p>Antes de embarcar un vehículo de motor
                                debes tener: Licencia (Registración),
                                Título del vehículo y una Copia de la licencia de conducir del dueño.
                            </p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>
                                    2
                                </h1>
                                <h2 className='beba-font'>Certificado NO MULTAS</h2>
                            </Card.Title>
                            <p>Certificado de ‘no multas pendientes’ de pago con sellos de $1.50 Emitido por el Dep. de Transportación y Obras Públicas (DTOP)  vigente por 2 días. Impresión de Internet no válida.
                            </p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>3</h1>
                                <h2 className='beba-font'>
                                Comprob. exportación
                                </h2>
                            </Card.Title>
                                <p>Comprobante (voucher) de exportación por $10.00 de la Colecturía (No sellos/stamps).</p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                                <Card.Title>
                                <h1 className='text-primary'>4</h1>
                                <h2 className='beba-font'>Inspección vehícular</h2>
                                </Card.Title>
                                    <p>Vehículo debe inspeccionarse en la División de Vehículos Hurtados (Estación de policía de la ciudad donde reside el vehículo). Válido por 5 días solamente.</p>
                            </Card.Body>
                        </Card>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row className='mb-5'>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>
                                    5
                                </h1>
                                <h2 className='beba-font'>Certificado NO deudas</h2>
                            </Card.Title>
                            <p>Certificado de no deudas con ACCA* Emitido en la oficinas de San Juan, PR. (Arterial Hostos, Esq. Chardón - San Juan, PR 00936 Tel. 787 759-8989). Favor poner la tablilla en el “dash” al llegar al puerto.
                            </p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>
                                    6
                                </h1>
                                <h2 className='beba-font'>Recibo de lavado</h2>
                            </Card.Title>
                            <p>Recibo de lavado a presión incluyendo el exterior, motor y chasis. ​
                            </p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>7</h1>
                                <h2 className='beba-font'>
                                Declaración de auth.
                                </h2>
                            </Card.Title>
                                <p>Declaración jurada (original) que autoriza a PRautotransport y el puerto hacer el envío. (Documento original con sello de Puerto Rico).</p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                                <Card.Title>
                                <h1 className='text-primary'>8</h1>
                                <h2 className='beba-font'>Declaración dueño</h2>
                                </Card.Title>
                                    <p>Declaración jurada (original) hecha en Puerto Rico más copia de la licencia del dueño autorizando el embarque. (Es requerido cuando el dueño no esté presente). </p>
                            </Card.Body>
                        </Card>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row className='mb-5'>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>
                                    9
                                </h1>
                                <h2 className='beba-font'>Carta del Banco</h2>
                            </Card.Title>
                            <p> Carta del banco (original) autorizando el embarque en caso de tener alguna financiación. 
                            </p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        <Card style={{minHeight: '400px', border: '0'}} className='shadow mx-1'>
                            <Card.Body>
                            <Card.Title>
                                <h1 className='text-primary'>
                                    10
                                </h1>
                                <h2 className='beba-font'>Impuesto de importación</h2>
                            </Card.Title>
                            <p>Si el envío del vehículo es hacia Puerto Rico, usted es responsable de pagar el impuesto de importación del vehículo. 
                            </p>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        </Col>
                        <Col md={3} xs={6} className='my-5'>
                        </Col>
                    </Row>
                </div>
                </Slider>

        </div>
         </>
    );

};

export default Requirements;

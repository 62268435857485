import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import FooterBg from "../images/footerbg.png";
import whiteLogo from "../images/logo-white.png";


const Footer = ({scrollToForm}) => {


  return (
    <footer className="d-flex flex-column justify-content-center mt-auto">
      {/* Section with background image */}
      <section
        className="text-center d-none d-md-block"
        style={{
          backgroundImage: `url(${FooterBg})`,
          padding: "10em",
          position: "relative",
        }}
      >
        {/* Dark overlay */}
        <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-75"></div>

        {/* Centered content */}
        <div className="d-flex flex-column align-items-center">
          <h3
            className="beba-font text-white"
            style={{ position: "relative", zIndex: 1 }}
          >
            ¿Necesitas ayuda?
          </h3>
          <h3
            className="beba-font text-white mb-0"
            style={{ position: "relative", zIndex: 1, fontSize: "7em" }}
          >
            Contact <span style={{ color: "#81CEFF" }}>US</span>
          </h3>
          <div className="d-flex justify-content-center align-items-center">
            <h3
              className="text-white w-75"
              style={{ position: "relative", zIndex: 1, fontSize: "1em" }}
            >
              Ave. Boulevard, W-7, Ste. 2, Levittown, Toa Baja, PR 00949,
              Horario de atención: Lunes a Viernes 10am - 6pm.
            </h3>
          </div>
          <div
            className="text-center"
            style={{ position: "relative", zIndex: 1 }}
          >
            {" "}
            {/* Button container */}
            <Button className="me-5"  onClick={scrollToForm}>Contactanos</Button>
            <a className="btn btn-outline-success" href='tel:+17877416032'>(1) 787-741-6032</a>
          </div>
        </div>
      </section>

      {/* Section with dark background */}
      <section className="bg-dark p-3">
        <Container>
          <Row>
            <Col
              md={4}
              className=" d-none d-md-flex justify-content-center align-items-center"
            >
              <ul className="list-unstyled">
                <li className="text-success">Home</li>
                <li className="text-white border-bottom py-2">Beneficios</li>
                <li className="text-white border-bottom  py-2">Servicios</li>
                <li className="text-white border-bottom py-2">
                  Tiempos de transito
                </li>
                <li className="text-white border-bottom py-2">Cotiza</li>
              </ul>
            </Col>
            <Col
              md={4}
              className=" d-none d-md-flex justify-content-center align-items-center"
            >
              <ul className="list-unstyled">
                <li className="text-success">Customer center</li>
                <li className="text-white border-bottom py-2">About us</li>
                <li className="text-white border-bottom py-2">Documentos</li>
                <li className="text-white border-bottom py-2">FAQ</li>
                <li className="text-white border-bottom py-2">Contacto</li>
              </ul>
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-center align-items-center p-5"
            >
              <img src={whiteLogo} alt="logo" width={200} />
            </Col>
          </Row>
          <hr className="text-white" />
          <Row>
            <Col md={4} className="text-white text-center my-2">
                ¿Preguntas? Llámanos al <span className="text-secondary ms-1"> <a href='tel:+17877416032'>(1) 787-741-6032</a> </span>
            </Col>
            <Col md={4} className="text-white text-center my-2">
              <span>info@puertoricocartransfer.com</span>
              <a href="https://www.instagram.com/puertoricocartransfer/" target="_blank" rel="noreferrer" style={{ display: 'inline-block', verticalAlign: 'middle' }} className="mx-3">
                  <img width="24" alt="Instagram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" height="24" />
              </a>
           </Col>
            <Col md={4} className="text-white text-center my-2">
            ©2024 Puerto Rico Car Transfer
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Col, Row, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assuming the imports for your images and arrow SVG
import Image1 from '../images/slider1.png';
import Image2 from '../images/slider2.png';
import Image3 from '../images/slider3.png';
import SliderArrow from '../images/sliderarrow.svg';

const CustomSliderComponent = ({ scrollToForm }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const images = [Image1, Image2, Image3]; // Array of images
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once immediately to set initial state
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const mobileSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <CustomNextMobileArrow  currentSlide={currentSlide} images={images} />,

    };

    const desktopSettings = {
        ...mobileSettings,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
        nextArrow: <CustomNextArrow />,
        prevArrow: <SamplePrevArrow currentSlide={currentSlide} images={images} />,
    };

    const settings = isMobile ? mobileSettings : desktopSettings;

    function CustomNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-slick-arrow`}
                style={{
                    ...style,
                    display: "block",
                    background: `url(${SliderArrow}) no-repeat center center`,
                    backgroundSize: 'contain',
                    width: '36px',
                    height: '36px',
                    top: '79%',
                    right: '5%',
                    position: 'absolute',
                    zIndex: 1
                }}
                onClick={onClick}
            />
        );
    }

    function CustomNextMobileArrow({ className, style, onClick, currentSlide, images }) {
        let prevSlideIndex = currentSlide - 1;
        if (prevSlideIndex < 0) {
            prevSlideIndex = images.length - 1;
        }
        const prevSlideImage = images[prevSlideIndex];
        // Format slide number for display
        const formattedSlideNumber = `0${currentSlide + 1}`;

        // Determine text based on current slide
        const slideTexts = [
            "Comienza por hacer la reservación",
            "¿Buscas transportar tu Vehículo a Puerto Rico O Estados Unidos?",
            "Somos la manera más rápida y confiable de envíar vehículos"
        ];
        const currentSlideText = slideTexts[currentSlide];

        const arrowStyle = {
            ...style,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: '#007bff',
            color: 'white',
            width: '280px', // Example width adjustment for the whole arrow
            height: '100px',
            top: '40%',
            left: '20%',
            zIndex: 999,
            cursor: 'pointer',
            position: 'absolute',
            borderRadius: '6px'
        };
    
        const imageContainerStyle = {
            position: 'relative',
            display: 'flex',
            alignItems: 'center', // Center content vertically
            justifyContent: 'center', // Center content horizontally
            width: '50%', // Half the width
            height: '100%',
            
        };
    
        const overlayStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(2, 15, 38, 0.5)',
            zIndex: 999,
            borderTopLeftRadius: '6px', 
            borderBottomLeftRadius: '6px'
        };
    
        const textStyle = {
            width: '50%', // Ensure container is wide enough for the intended text
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '10px',
            fontSize: '12px',
            color: 'white',
            position: 'relative',
            height: '100%', // Adjust height as needed to allow for multiline text
        };
        
        return (
            <div className={className} style={arrowStyle} onClick={onClick}>
                <div style={imageContainerStyle}>
                    <img src={prevSlideImage} alt="Previous Slide" style={{ width: '100%', height: '100%', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }} />
                    <div style={overlayStyle}></div>
                </div>
                <div style={textStyle}>
                    <p style={{ position: 'absolute', top: '20px', left: '10px', margin: 0, fontWeight: 'bold', whiteSpace: 'normal' }}>{formattedSlideNumber}</p>
                    <p className='px-1' style={{ position: 'absolute', bottom: '10px', left: '10px', margin: 0, whiteSpace: 'normal', lineHeight: '1.5', fontSize: '11px' }}>{currentSlideText}</p>
                </div>
            </div>
        );
    }


    function SamplePrevArrow({ className, style, onClick, currentSlide, images }) {
        let prevSlideIndex = currentSlide - 1;
        if (prevSlideIndex < 0) {
            prevSlideIndex = images.length - 1;
        }
        const prevSlideImage = images[prevSlideIndex];
        // Format slide number for display
        const formattedSlideNumber = `0${currentSlide + 1}`;

        // Determine text based on current slide
        const slideTexts = [
            "Comienza por hacer la reservación",
            "¿Buscas transportar tu Vehículo a Puerto Rico O Estados Unidos?",
            "Somos la manera más rápida y confiable de envíar vehículos"
        ];
        const currentSlideText = slideTexts[currentSlide];

        const arrowStyle = {
            ...style,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: '#007bff',
            color: 'white',
            width: '280px', // Example width adjustment for the whole arrow
            height: '100px',
            top: '79%',
            left: '10%',
            zIndex: 999,
            cursor: 'pointer',
            position: 'absolute',
            borderRadius: '6px'
        };
    
        const imageContainerStyle = {
            position: 'relative',
            display: 'flex',
            alignItems: 'center', // Center content vertically
            justifyContent: 'center', // Center content horizontally
            width: '50%', // Half the width
            height: '100%',
            
        };
    
        const overlayStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(2, 15, 38, 0.5)',
            zIndex: 999,
            borderTopLeftRadius: '6px', 
            borderBottomLeftRadius: '6px'
        };
    
        const textStyle = {
            width: '50%', // Ensure container is wide enough for the intended text
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '10px',
            fontSize: '12px',
            color: 'white',
            paddingRight: '4px',
            position: 'relative',
            height: '100%', // Adjust height as needed to allow for multiline text
        };
        
        return (
            <div className={className} style={arrowStyle} onClick={onClick}>
                <div style={imageContainerStyle}>
                    <img src={prevSlideImage} alt="Previous Slide" style={{ width: '100%', height: '100%', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }} />
                    <div style={overlayStyle}></div>
                </div>
                <div style={textStyle}>
                    <p style={{ position: 'absolute', top: '20px', left: '10px', margin: 0, fontWeight: 'bold', whiteSpace: 'normal' }}>{formattedSlideNumber}</p>
                    <p style={{ position: 'absolute', bottom: '10px', left: '10px', right: '5px', margin: 0, whiteSpace: 'normal', lineHeight: '1.5', fontSize: '10px' }}>{currentSlideText}</p>
                </div>
            </div>
        );
    }
    

    const slideContent = [
        {
            image: Image1,
            primaryText: 'Transporte terreste',
            secondaryText: '¿Buscas Transportar tu Vehículo a Puerto Rico O ESTADOS UNIDOS?',
            description: 'Nuestra guía te ofrece precios competitivos y calidad garantizada.',
            buttonText: 'Cotiza tu envio',
            buttonAction: scrollToForm
        },
        {
            image: Image2,
            primaryText: '¿Quiénes somos?',
            secondaryText: 'Somos la manera más rapida y confiable de envíar vehículos',
            description: 'Un proveedor líder en servicios de envío de automóviles entre los puertos de San Juan, PR, y los puertos de Florida, Pennsylvania. Gestionamos el transporte terrestre dentro de los Estados Unidos hasta la puerta de tu casa y manejamos todo el papeleo.',
            buttonText: 'Cotiza tu envio',
            buttonAction: scrollToForm
        },
        {
            image: Image3,
            primaryText: 'Compara los mejores precios y servicios',
            secondaryText: 'Comienza por hacer la reservación',
            description: 'Recibe hasta cinco cotizaciones de diferentes transportistas para que tomes la mejor decisión.',
            buttonText: 'Reserva ya',
            buttonAction: scrollToForm
        },
    ];

    return (
    <>
    <div id='slider'></div>
        <Slider {...settings} className="custom-slider d-none d-md-block" >
        {slideContent.map((content, index) => (
            <div key={index}>
                <Row>
                    <Col md={6} className="position-relative">
                        <img src={content.image} alt={`Slide ${index + 1}`} className="img-fluid" />
                    </Col>
                    <Col md={6} className="d-flex flex-column justify-content-center p-5">
                        <p className='text-primary mb-3' style={{marginBottom: '0', fontSize: '20px'}}>{content.primaryText}</p>
                        <h1 className='beba-font' style={{fontSize: '3em', lineHeight: '0.75'}}>{content.secondaryText}</h1>
                        <p style={{fontSize: '1em'}}>{content.description}</p>
                        <Button variant="primary" className='w-25 p-2' onClick={content.buttonAction}>{content.buttonText}</Button>
                    </Col>
                </Row>
            </div>
        ))}
        </Slider>
        <Slider {...settings} className="d-block d-md-none custom-slider">
        {slideContent.map((content, index) => (
            <div key={index}>
                <Row>
                    <Col md={6} className="position-relative">
                        <img src={content.image} alt={`Slide ${index + 1}`} className="img-fluid" />
                    </Col>
                    <Col md={6} className="d-flex flex-column justify-content-center p-5 mt-5">
                        <p className='text-primary' style={{fontSize: '21px'}}>{content.primaryText}</p>
                        <h1 className='beba-font' style={{fontSize: '3em', lineHeight: '1'}}>{content.secondaryText}</h1>
                        <p style={{fontSize: '18px'}}>{content.description}</p>
                        <Button variant="primary" className='w-100 p-2' onClick={content.buttonAction}>{content.buttonText}</Button>
                    </Col>
                </Row>
            </div>
        ))}
        </Slider>
    </>
    );
};

export default CustomSliderComponent;

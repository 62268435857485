import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import backgroundImage from "../images/formimage.png"; // Adjust the path as necessary
import StepOne from "./Form/StepOne";
import prCar from "../images/pr_car.png";
import prMoto from "../images/pr_moto.png";
import prCarro from "../images/pr_carro.png";
import prYate from "../images/pr_yate.png";
import prBarco from "../images/pr_barco.png";
import prCamion from "../images/pr_camion.png";

const FormSection = ({ scrollToForm }) => {
  return (
    <Row className="d-flex justify-content-between align-items-center my-2">
      <Col xs={12} className="d-flex d-md-none">
        <img
          src={backgroundImage}
          alt="Responsive background"
          style={{
            width: "100vw",
            height: "40vh",
            objectFit: "cover",
          }}
        />
      </Col>

      <Col xs={12} className="d-flex d-md-none">
        <div className="mt-sm-5 pt-5 text-start px-5">
          <p style={{ color: "#D10525", fontSize: "1.3em" }} className="mt-3">
            Compara los mejores precios y servicios
          </p>
          <p
            className="beba-font"
            style={{ fontSize: "2.99em", lineHeight: "1.2", marginTop: "-10px" }}
          >
            ¡Encuentra el mejor Precio <br />
            <span style={{ color: "#0542C7" }}>
              para Trasladar tu Vehículo
            </span>
            <br />a Puerto Rico | USA!
            <span>
              <img className="ms-3" width={44} src={prBarco} alt="Barco" />
              <img className="ms-3" width={44} src={prCamion} alt="Camión" />
              <img className="ms-3" width={44} src={prCarro} alt="Carro" />
              <img className="ms-3" width={44} src={prYate} alt="Yate" />
              <img className="ms-3" width={44} src={prMoto} alt="Moto" />
              <img className="ms-3" width={44} src={prCar} alt="Car" />
            </span>
          </p>
          <p className="mt-sm-5 fw-bold" style={{ fontSize: "1.1em" }}>
            ¡Descubre Nuestras Soluciones en Transporte de Autos!
          </p>
        </div>
      </Col>

      <Col md={6} className="d-none d-md-block">
        <div className="ms-5 mt-sm-5 text-center text-md-start">
          <p
            style={{ color: "#D10525", marginBottom: "-5px" }}
            className="fs-6"
          >
            Compara los mejores precios y servicios
          </p>
          <p
            className="beba-font"
            style={{ fontSize: "3.5em", lineHeight: "1.2" }}
          >
            ¡Encuentra el mejor Precio <br />
            <span style={{ color: "#0542C7" }}>
              para Trasladar tu Vehículo
            </span>{" "}
            <br />
            a Puerto Rico | USA! <br />
            <span>
              <img className="mx-3" width={70} src={prBarco} alt="Barco" />
            </span>
            <span>
              <img className="mx-3" width={70} src={prCamion} alt="Camión" />
            </span>
            <span>
              <img className="mx-3" width={70} src={prCarro} alt="Carro" />
            </span>
            <span>
              <img className="mx-3" width={70} src={prYate} alt="Yate" />
            </span>
            <span>
              <img className="mx-3" width={70} src={prMoto} alt="Moto" />
            </span>
            <span>
              <img className="mx-3" width={70} src={prCar} alt="Car" />
            </span>
          </p>
          <p className="fs-6 mt-sm-5">
            ¡Descubre Nuestras Soluciones en Transporte de Autos!
          </p>
        </div>
      </Col>

      <Col md={6} className="d-none d-md-block" style={{ padding: "3em" }}>
        <div
          style={{
            borderRadius: "12px",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          <Card
            className="p-5"
            style={{ position: "relative", borderRadius: "12px" }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "100% 1000px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                filter: "blur(1.5px)", // Adjust the pixel value to increase/decrease the blur effect
                borderRadius: "12px", // Apply the same border radius here
                zIndex: 0,
              }}
            ></div>

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage:
                  "linear-gradient(180deg, rgba(2,15,38,1) 0%, rgba(2,15,38,0) 100%)",
                zIndex: 1,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(2, 15, 38, 0.4)",
                zIndex: 1,
              }}
            ></div>
            <Card.Body>
              <div style={{ position: "relative", zIndex: 1 }}>
                <p
                  className="text-center text-white"
                  style={{
                    marginBottom: "-5px",
                    fontSize: "24px",
                    letterSpacing: "1px",
                  }}
                >
                  Reserva tu cupo
                </p>
                <div className="mb-4 d-flex justify-content-center">
                  <span className="beba-font text-white fs-5 text-nowrap">
                    ¡EMPECEMOS CON TU cotización, ES
                    <span className="ms-1" style={{ color: "#81CEFF" }}>
                      GRATIS!
                    </span>
                  </span>
                </div>
                <StepOne></StepOne>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Col>

      <Col
        xs={12}
        className="d-flex flex-column d-md-none justify-content-center align-items-center px-5"
      >
        <Button
          className="mt-5 p-3 w-100"
          onClick={scrollToForm}
          style={{ fontSize: "1em" }}
        >
          DESDE USA A PUERTO RICO
        </Button>
        <Button
          variant="outline-secondary"
          className="mt-3 p-3 w-100"
          onClick={scrollToForm}
          style={{ fontSize: "1em" }}
        >
          DESDE PUERTO RICO A USA
        </Button>
      </Col>
    </Row>
  );
};

export default FormSection;

import React from 'react';

const CenteredSection = ({scrollToForm}) => {
  // Styles for the container
  const containerStyle = {
    minheight: '40vh', // Take up full viewport height
    backgroundColor: '#0C265F', // Background color of the section
    color: '#FFFFFF', // Text color for contrast
  };


  return (
    <div style={containerStyle} className='d-none d-md-block'>
      <div  className='d-flex justify-content-center align-items-center'>
        <p className='beba-font fs-5 mt-5' style={{letterSpacing: '4px'}}>Preparate para el envío</p>
      </div>
      <div  className='d-flex justify-content-center align-items-center'>
        <h1 className='beba-font mb-0' style={{fontSize: '4em', letterSpacing: "5px"}}>REQUERIMIENTOS</h1>
      </div>
      <div  className='d-flex justify-content-center align-items-center'>
        <p className='w-75 text-center' style={{fontSize: '1.3em'}}>Si necesitas ayuda, no dudes en comunicarte con nosotros y gestionaremos el proceso</p>
      </div>
      <div  className='d-flex justify-content-center align-items-center'>
        <button className='btn btn-primary px-2 mt-3 mb-5' onClick={scrollToForm}>Realiza una solictud</button>
      </div>
    </div>
  );
};

export default CenteredSection;

import React from "react";
import { Button, Container, Card, Col, Row, Accordion } from "react-bootstrap";
import backgroundImage from "../../images/formimage.png"; // Adjust the path as necessary
import haciendabg from "../../images/haciendabg.png"; // Adjust the path as necessary
import { useCustomNavigate } from '../../utils/customNavigation';

import Slider from "react-slick";

const Documentos = () => {
  const customNavigate = useCustomNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };
  const accordionItems = [
    { title: 'alarmas desconectadas', content: 'Nos gusta mantener las cosas eficientes y directas. Pedimos a nuestros clientes que proporcionen detalles básicos sobre sus vehículos, como el tamaño, el peso, la distancia entre la recogida y la entrega, y la elección entre el transporte de vehículos abierto o cerrado. Al hacerlo, podemos evaluar con precisión las tarifas de transporte del vehículo en consecuencia. A diferencia de otras compañías de transporte, no solicitamos tarifas adicionales.' },
    { title: 'seguro opcional', content: 'Seguro Marítimo opcional disponible.  $100 por una cubierta de hasta $25,000. El mismo tiene deducible de US$1,000.' },
    { title: 'Sellos ilegibles', content: 'Vehículos sin los sellos con los números de identificación legibles en las puertas, bonete, tapa de baúl y “dashboard”. Todas las partes reemplazadas tienen que ser documentadas.' },
    { title: 'Condiciones irregulares', content: 'No enviamos vehículos inoperables, con ventanas rotas, dañadas. (Si el vehículo deja de funcionar después de ser recibido, se cobrará un adicional de $150.00).' },
    { title: 'Vehiculos peligrosos', content: 'No se aceptará para el envío cualquier vehículo que se considere peligroso .' },
    { title: 'Articulos personales', content: 'No aceptamos vehículos con artículos personales por dentro. Los únicos artículos aceptables son los accesorios removibles o instalados permanentemente al vehículos. E.g. Sillas para niños.' },
    { title: 'Tanque de gasolina', content: 'Al momento de entrega no se aceptarán vehículos que contengan más de un cuarto de gasolina en el tanque.' },
    { title: 'Envío especial', content: 'Considere que los vehículos de gran tamaño se envían de formas especiales dependiendo de sus dimensiones. Por favor llámenos al 1-787-741-6032 para coordinar el proceso.' },
    { title: 'arbitrios externos', content: 'Los vehículos que han sido comprados fuera del territorio de Puerto Rico deberán asumir el costo de impuestos en la isla que se conoce como arbitrios.' },
  ];

  return (
    <>
      <div className="d-flex justify-content-center align-items-center text-white bg-dark">
        <p className="beba-font fs-5 mt-5" style={{ letterSpacing: "2px" }}>
          Preparate para el envío
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center  text-white bg-dark">
        <h1
          className="beba-font mb-0"
          style={{ fontSize: "4em", letterSpacing: "2px" }}
        >
          REQUERIMIENTOS
        </h1>
      </div>
      <div className="d-flex justify-content-center align-items-center  text-white bg-dark">
        <p className="w-75 text-center" style={{ fontSize: "1.3em" }}>
          Si necesitas ayuda, no dudes en comunicarte con nosotros y
          gestionaremos el proceso
        </p>
      </div>
      <div className="d-flex flex-column align-items-center  text-white bg-dark">
        <button className="btn btn-primary px-5 py-3 mt-3 mb-3" onClick={() => customNavigate('#mobileForm')}>
          Cotiza con nosotros
        </button>
        <a href="tel:+17877416032" className="btn btn-outline-success px-5 py-3 mt-3 mb-5">
          +1 (787) 741 6032
        </a>

      </div>
      <div>
        <img
          src={backgroundImage}
          alt="Responsive background"
          style={{
            width: "100vw",
            height: "40vh",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="p-5">
        <p className="text-primary">Compara los mejores precios y servicios</p>
        <h1 className="beba-font">Comienza por hacer la reservación</h1>
        <p className="mt-2 mb-4">
          Recibe hasta cinco cotizaciones de diferentes transportistas para que
          tomes la mejor decisión.
        </p>
        <Button className="p-2 text-center w-100">RESERYA YA</Button>
        <Button
          variant="outline-secondary"
          className="p-2 mt-3 text-center w-100"
          onClick={() => window.location.href = 'tel:+17877416032'}
        >
          +1 (787) 741 6032
        </Button>
      </div>

      <div style={{backgroundColor: '#ECF8FF'}} className="py-3">
        <h1 className="text-center text-primary beba-font mt-5">PROCEDIMIENTOS PREVIOS</h1>
        <Slider {...settings} className="px-5 mb-5">
          <div className="my-3">
            <Row>
              <Col xs={12} className>
                <Card
                  style={{ minHeight: "300px", border: "0" }}
                  className="shadow mx-1"
                >
                  <Card.Body>
                    <Card.Title>
                      <h1 className="text-primary">1</h1>
                      <h2 className="beba-font">Requisitos completos</h2>
                    </Card.Title>
                    <p>
                      Antes de embarcar un vehículo de motor debes tener:
                      Licencia (Registración), Título del vehículo y una Copia
                      de la licencia de conducir del dueño.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="my-3">
            <Col xs={12} className>
              <Card
                style={{ minHeight: "300px", border: "0" }}
                className="shadow mx-1"
              >
                <Card.Body>
                  <Card.Title>
                    <h1 className="text-primary">2</h1>
                    <h2 className="beba-font">Certificado NO MULTAS</h2>
                  </Card.Title>
                  <p>
                    Certificado de ‘no multas pendientes’ de pago con sellos de
                    $1.50 Emitido por el Dep. de Transportación y Obras Públicas
                    (DTOP)  vigente por 2 días. Impresión de Internet no válida.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="my-3">
            <Col xs={12} className>
              <Card
                style={{ minHeight: "300px", border: "0" }}
                className="shadow mx-1"
              >
                <Card.Body>
                  <Card.Title>
                    <h1 className="text-primary">3</h1>
                    <h2 className="beba-font">Comprob. exportación</h2>
                  </Card.Title>
                  <p>
                    Comprobante (voucher) de exportación por $10.00 de la
                    Colecturía (No sellos/stamps).
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="my-3">
            <Col xs={12} className>
              <Card
                style={{ minHeight: "300px", border: "0" }}
                className="shadow mx-1"
              >
                <Card.Body>
                  <Card.Title>
                    <h1 className="text-primary">4</h1>
                    <h2 className="beba-font">Inspección vehícular</h2>
                  </Card.Title>
                  <p>
                    Vehículo debe inspeccionarse en la División de Vehículos
                    Hurtados (Estación de policía de la ciudad donde reside el
                    vehículo). Válido por 5 días solamente.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="my-3">
            <Row>
              <Col xs={12} className>
                <Card
                  style={{ minHeight: "300px", border: "0" }}
                  className="shadow mx-1"
                >
                  <Card.Body>
                    <Card.Title>
                      <h1 className="text-primary">5</h1>
                      <h2 className="beba-font">Certificado NO deudas</h2>
                    </Card.Title>
                    <p>
                      Certificado de no deudas con ACCA* Emitido en la oficinas
                      de San Juan, PR. (Arterial Hostos, Esq. Chardón - San
                      Juan, PR 00936 Tel. 787 759-8989). Favor poner la tablilla
                      en el “dash” al llegar al puerto.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="my-3">
            <Col xs={12} className>
              <Card
                style={{ minHeight: "300px", border: "0" }}
                className="shadow mx-1"
              >
                <Card.Body>
                  <Card.Title>
                    <h1 className="text-primary">6</h1>
                    <h2 className="beba-font">Recibo de lavado</h2>
                  </Card.Title>
                  <p>
                    Recibo de lavado a presión incluyendo el exterior, motor y
                    chasis. ​
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="my-3">
            <Col xs={12} className>
              <Card
                style={{ minHeight: "300px", border: "0" }}
                className="shadow mx-1"
              >
                <Card.Body>
                  <Card.Title>
                    <h1 className="text-primary">7</h1>
                    <h2 className="beba-font">Declaración de auth.</h2>
                  </Card.Title>
                  <p>
                    Declaración jurada (original) que autoriza a PRautotransport
                    y el puerto hacer el envío. (Documento original con sello de
                    Puerto Rico).
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="my-3">
            <Col xs={12} className>
              <Card
                style={{ minHeight: "300px", border: "0" }}
                className="shadow mx-1"
              >
                <Card.Body>
                  <Card.Title>
                    <h1 className="text-primary">8</h1>
                    <h2 className="beba-font">Declaración dueño</h2>
                  </Card.Title>
                  <p>
                    Declaración jurada (original) hecha en Puerto Rico más copia
                    de la licencia del dueño autorizando el embarque. (Es
                    requerido cuando el dueño no esté presente). 
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="my-3">
            <Row>
              <Col xs={12} className>
                <Card
                  style={{ minHeight: "300px", border: "0" }}
                  className="shadow mx-1"
                >
                  <Card.Body>
                    <Card.Title>
                      <h1 className="text-primary">9</h1>
                      <h2 className="beba-font">Carta del Banco</h2>
                    </Card.Title>
                    <p>
                       Carta del banco (original) autorizando el embarque en
                      caso de tener alguna financiación. 
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="my-3">
            <Col xs={12} className>
              <Card
                style={{ minHeight: "300px", border: "0" }}
                className="shadow mx-1"
              >
                <Card.Body>
                  <Card.Title>
                    <h1 className="text-primary">10</h1>
                    <h2 className="beba-font">Impuesto de importación</h2>
                  </Card.Title>
                  <p>
                    Si el envío del vehículo es hacia Puerto Rico, usted es
                    responsable de pagar el impuesto de importación del
                    vehículo.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Slider>
      </div>


      <div
        className="bg-dark d-flex d-md-none justify-content-center align-items-center w-100"
        style={{ height: "35vh"}}
      >
        <Container className="text-center beba-font">
          <h1 style={{ color: "#81CEFF" }} className="px-3">
            TODAS LAS TABLILLAS DE PUERTO RICO SERÁN REMOVIDAS POR LA POLICÍA
            ANTES DE EMBARCACIÓN. LAS TABLILLAS NO SE DEVUELVEN.
          </h1>
        </Container>
      </div>
      <div>
        <img
          src={haciendabg}
          alt="Responsive background"
          style={{
            width: "100vw",
            height: "40vh",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="p-5">
        <p className="text-primary">Conoce los costos</p>
        <h1 className="beba-font">¿Quieres estimar el impuesto de importación?</h1>
        <p className="mt-2 mb-4">
          Para determinar el requisito del impuesto estimado para el vehículo específico, por favor tenga VIN listo.
        </p>
        <Button className="p-3 text-center w-100"  onClick={() => window.open('https://siscon.hacienda.gobierno.pr/Siscon/Portal/ARBAW951Form.aspx', '_blank')}>DEPARTAMENTO DE HACIENDA</Button>
      </div>

      <section style={{backgroundColor: '#ECF8FF'}} id='requirements' className='d-block d-md-none pb-5 mb-5 '>
            <div className='d-flex justify-content-center align-items-center'> 
                <h1 className='beba-font text-primary mt-5' style={{fontSize: '4em'}}>NO ES PERMITIDO</h1>
            </div>
            <div className='w-100 mx-auto bg-transparent px-4'>
            <Accordion defaultActiveKey="3" className='bg-transparent'>
                {accordionItems.map((item, index) => (
                    <Accordion.Item eventKey={`${index}`} key={index}>
                    <Accordion.Header className='beba-font bg-transparent mt-2'> {item.title}</Accordion.Header>
                    <Accordion.Body className='bg-transparent mx-2'>
                        {item.content}
                    </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            </div>
        </section>
    </>
  );
};

export default Documentos;

import React from "react";
import {Button } from "react-bootstrap";
import FooterBg from "../../images/footerbg.png";
import MobileForm from '../Form/MobileForm'
const ContactUs = () => {
  return (
    <>
      <section
        className="text-center d-block d-md-none img-fluid"
        style={{
          backgroundImage: `url(${FooterBg})`,
          paddingTop: "5em",
          paddingBottom: "5em",
          position: "relative",
        }}
      >
        {/* Dark overlay */}
        <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-75"></div>

        {/* Centered content */}
        <div className="d-flex flex-column align-items-center">
          <h3
            className="beba-font text-white fs-6"
            style={{ position: "relative", zIndex: 1 }}
          >
            ¿Necesitas ayuda?
          </h3>
          <h3
            className="beba-font text-white mb-0"
            style={{ position: "relative", zIndex: 1, fontSize: "4.5em" }}
          >
            Contact <span style={{ color: "#81CEFF" }}>US</span>
          </h3>
          <div className="d-flex justify-content-center align-items-center">
            <h3
              className="text-white w-75"
              style={{ position: "relative", zIndex: 1, fontSize: "1em"}}
            >
              Ave. Boulevard, W-7, Ste. 2, Levittown, Toa Baja, PR 00949,
              Horario de atención: Lunes a Viernes 10am - 6pm.
            </h3>
          </div>
          <div
            className="d-flex flex-column mt-3 w-75"
            style={{ position: "relative", zIndex: 1 }}
          >
            <Button className="my-4 p-2">Contactanos</Button>
            <a href='tel:+17877416032' className="btn btn-outline-success m-0" style={{ textDecoration: 'none' }}>(1) 787-741-6032</a>
          </div>
        </div>
      </section>
      <section>
      <div>
                <div className='p-5 mb-0'>
                  <p className='text-primary mb-0'>
                      Reserva tu cupo
                  </p>
                  <h1 className='beba-font pe-4'>
                      Recibe hasta 5 cotizaciones
                  </h1>
                </div>
                  <MobileForm/> 
                
              </div>
      </section>
    </>
    
  );
};

export default ContactUs;

import React from 'react';
import { Container } from 'react-bootstrap';

const HeroSection = () => {
    return (
        <div className="bg-dark d-none d-md-flex justify-content-center align-items-center w-100" style={{height: '20vh', padding: '7rem'}}>
            <Container className="text-center beba-font">
                <h2 style={{color: '#81CEFF'}}>TODAS LAS TABLILLAS DE PUERTO RICO SERÁN REMOVIDAS POR LA POLICÍA ANTES DE EMBARCACIÓN. LAS TABLILLAS NO SE DEVUELVEN.</h2>
            </Container>
        </div>
    );
};

export default HeroSection;

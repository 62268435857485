import React from 'react'
import { Accordion} from 'react-bootstrap';

export default function NoPermitido() {
    const accordionItems = [
        { title: 'alarmas desconectadas', content: 'Nos gusta mantener las cosas eficientes y directas. Pedimos a nuestros clientes que proporcionen detalles básicos sobre sus vehículos, como el tamaño, el peso, la distancia entre la recogida y la entrega, y la elección entre el transporte de vehículos abierto o cerrado. Al hacerlo, podemos evaluar con precisión las tarifas de transporte del vehículo en consecuencia. A diferencia de otras compañías de transporte, no solicitamos tarifas adicionales.' },
        { title: 'seguro opcional', content: 'Seguro Marítimo opcional disponible.  $100 por una cubierta de hasta $25,000. El mismo tiene deducible de US$1,000.' },
        { title: 'Sellos ilegibles', content: 'Vehículos sin los sellos con los números de identificación legibles en las puertas, bonete, tapa de baúl y “dashboard”. Todas las partes reemplazadas tienen que ser documentadas.' },
        { title: 'Condiciones irregulares', content: 'No enviamos vehículos inoperables, con ventanas rotas, dañadas. (Si el vehículo deja de funcionar después de ser recibido, se cobrará un adicional de $150.00).' },
        { title: 'Vehiculos peligrosos', content: 'No se aceptará para el envío cualquier vehículo que se considere peligroso .' },
        { title: 'Articulos personales', content: 'No aceptamos vehículos con artículos personales por dentro. Los únicos artículos aceptables son los accesorios removibles o instalados permanentemente al vehículos. E.g. Sillas para niños.' },
        { title: 'Tanque de gasolina', content: 'Al momento de entrega no se aceptarán vehículos que contengan más de un cuarto de gasolina en el tanque.' },
        { title: 'Envío especial', content: 'Considere que los vehículos de gran tamaño se envían de formas especiales dependiendo de sus dimensiones. Por favor llámenos al 1-787-741-6032 para coordinar el proceso.' },
        { title: 'arbitrios externos', content: 'Los vehículos que han sido comprados fuera del territorio de Puerto Rico deberán asumir el costo de impuestos en la isla que se conoce como arbitrios.' },
      ];
    
    return ( 
        <section style={{backgroundColor: '#ECF8FF'}} id='requirements' className='d-none d-md-block'>
            <div className='d-flex justify-content-center align-items-center'> 
                <h1 className='beba-font text-primary mt-5' style={{fontSize: '4em'}}>NO ES PERMITIDO</h1>
            </div>
            <div className='w-75 mx-auto bg-transparent p-5'>
            <Accordion defaultActiveKey="0" className='bg-transparent'>
                {accordionItems.map((item, index) => (
                    <Accordion.Item eventKey={`${index}`} key={index}>
                    <Accordion.Header className='beba-font bg-transparent'> {item.title}</Accordion.Header>
                    <Accordion.Body className='bg-transparent mx-5'>
                        {item.content}
                    </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            </div>
        </section>
    )
}
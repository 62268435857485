import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import HaciendaBg from '../images/haciendabg.png'
const Costos = () => {
    return (
        <div className="d-none d-md-flex justify-content-center align-items-center w-100" style={{height: '60vh'}}>
            <Container className='mt-5'>
                <Row>
                    <Col md={6} className='py-md-5 mt-md-1'>
                        <div  className="d-flex justify-content-start align-items-center ">
                            <p className='text-secondary fs-6'>
                                Conoces los costos
                            </p>
                        </div>
                        <div  className="d-flex align-items-center">
                            <h1 className='beba-font' style={{fontSize: '4em'}}>
                             ¿Quieres estimar el <span className='me-2 text-primary'> impuesto de importación ?</span>
                            </h1>
                        </div>
                        <div  className="d-flex justify-content-center align-items-center my-2">
                            <p style={{fontSize: '1.5em'}}>
                            Para determinar el requisito del impuesto estimado para el vehículo específico, por favor tenga su VIN listo.
                            </p>
                        </div>
                        <div  className="d-flex justify-content-start align-items-center my-3">
                        <Button className='p-2' style={{fontSize: '1.2em'}} onClick={() => window.open('https://siscon.hacienda.gobierno.pr/Siscon/Portal/ARBAW951Form.aspx', '_blank')}>
                          Departamento de hacienda
                        </Button>

                        </div>
                    </Col>
                    <Col md={6} > 
                        <img src={HaciendaBg} alt="haciendo"  style={{width: '100%'}} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Costos;
